<template>
  <UniversalDialog
    persistent
    overlay-opacity="0.6"
    max-width="800"
    :value="openDialog"
  >
    <template v-slot:footer>
      <UniversalButton
        v-if="!loadingTransactions"
        width="120"
        @click="dismiss()"
      >
        {{ $t("gbl-ok") }}
      </UniversalButton>
    </template>

    <template v-slot:title>
      <v-row
        ><v-col class="mr-1 text-left"
          ><span>{{ $t("topbar-transactions") }}</span></v-col
        ><v-col class="mr-1 text-right"
          ><v-btn style="margin-right: 0" icon @click="dismiss()"
            ><v-icon>mdi-close</v-icon></v-btn
          ></v-col
        ></v-row
      >
    </template>

    <template v-slot:default>
      <v-card class="card-shadow border-radius-xl ma-4">
        <v-card-text class="px-0 py-0">
          <v-progress-circular
            v-if="loadingTransactions"
            class="mb-2"
            :indeterminate="true"
            :rotate="0"
            :size="32"
            :width="4"
            color="#3A416F"
          ></v-progress-circular>

          <UniversalDataTable
            :items-per-page="options.itemsPerPage"
            :must-sort="true"
            :headers="headers"
            :items="transactions"
            :options.sync="options"
            :totalItems="totalTransactions"
            :loading="loadingTransactions"
            class="table"
            hide-default-footer
            mobile-breakpoint="0"
            :search="true"
            :searchModel.sync="filter"
            :searchColumns="null"
            :pagination="true"
            :page.sync="detachedPageHandler"
            :disableFilterOnLoading="true"
          >
            <template v-slot:filterBar> </template>

            <template v-slot:item.time="{ item }">
              {{ item.time | toLocaleDateTime }}
            </template>

            <template v-slot:item.amount="{ item }">
              {{ item.amount | toCurrencySymbol }}
            </template>

            <template v-slot:item.context="{ item }">
              {{ item.context ? item.context.replace("whatsapp:", "") : "" }}
            </template>
          </UniversalDataTable>
        </v-card-text>
      </v-card>
    </template>
  </UniversalDialog>
</template>

<script>
import transactionsManager from "@/apis/transactions";
import staticData from "@/apis/static";
import config from "@/config";

export default {
  data() {
    return {
      openDialog: false,
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      options: {
        sortBy: ["id"],
        sortDesc: [true],
        itemsPerPage: 10,
      },
      filter: null,
      loadingTransactions: false,
      detachedPageHandler: 1,
      headers: [],
      transactions: [],
      totalTransactions: 0,
    };
  },

  mounted() {
    this.headers = [
      {
        text: this.$t("transactions-tbl-time"),
        align: "left",
        sortable: false,
        value: "time",
      },
      {
        text: this.$t("transactions-tbl-amount"),
        align: "left",
        sortable: false,
        value: "amount",
      },
      {
        text: this.$t("transactions-tbl-event"),
        align: "left",
        sortable: false,
        value: "event",
      },
      {
        text: this.$t("transactions-tbl-destination"),
        align: "left",
        sortable: false,
        value: "context",
      },
    ];
  },

  watch: {
    filter: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          if (this.detachedPageHandler != 1) this.detachedPageHandler = 1;
          else this.fetchTransactions();
        }, config.searchInputsTimeout);
      },
    },

    options: {
      handler() {
        if (this.detachedPageHandler != 1) this.detachedPageHandler = 1;
        else this.fetchTransactions();
      },
      deep: true,
    },

    detachedPageHandler: {
      handler() {
        this.fetchTransactions();
      },
    },
  },

  methods: {
    show() {
      this.openDialog = true;
      this.fetchTransactions();
    },

    hide() {
      this.openDialog = false;
    },

    dismiss() {
      this.hide();
    },

    closeDialog() {
      this.hide();
    },

    fetchTransactions() {
      this.loadingTransactions = true;
      let ctx = Object.assign({}, this.options);
      ctx.currentPage = this.detachedPageHandler;
      ctx.itemsPerPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
      ctx.filter = this.filter;
      ctx.sortBy = ctx.sortBy[0];
      ctx.sortDesc = ctx.sortDesc[0];

      transactionsManager
        .fetchTransactions(ctx)
        .then((res) => {
          this.transactions = res.items;
          this.totalTransactions = res.totalCount;

          this.loadingTransactions = false;
        })
        .catch((err) => {
          console.log(err);
          this.loadingTransactions = false;
        });
    },
  },
};
</script>
